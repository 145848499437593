<template>
    <div>
        <i class="material-icons" v-if="showIcon">assignment</i>

        <multiselect
            class   = "is-hotel-search"
            v-model = "hotelSelected"
            v-bind:options = "items"
            v-bind:loading = "isLoadingItems"
            v-bind:allow-empty="false"
            placeholder="tippen, um Hotel zu finden"
            :style="multiSelectStyle"
            label="name"
            track-by="id"
            select-label=""
            deselect-label="Löschen"
            v-bind:internal-search = "false"
            v-on:search-change="searchHotels"
            v-on:input="fillLocation"

        >

            <template slot="option" slot-scope="props">
                <template v-if="props.option.id !== -1">
                    <rating
                        v-bind:radius="12"
                        v-bind:rating="props.option.buspaketRating ? props.option.buspaketRating : -1"
                        class="level-item"
                    ></rating>

                    <div>
                        <h1>{{ props.option.name }}</h1>
                        <stars v-bind:count="props.option.hotelType && props.option.hotelType.stars"></stars>
                    </div>
                </template>

                <template v-else>{{ props.option.name }}</template>
            </template>

            <template slot="noResult">Keine passenden Hotels</template>

            <span slot="caret">
                <a
                    class  = "multiselect-crud-link"
                    href   = "/hotels?add"
                    title  = "ein neues Hotel hinzufügen"
                    target = "_blank"
                ><i class="material-icons">add</i></a>
            </span>
        </multiselect>
    </div>
</template>


<script>
import axios           from 'axios';
import uniqBy          from 'lodash/uniqBy';
import { Multiselect } from '@components/form';
import { notifyError } from '@components/Notification';
import Rating          from '@components/Rating';
import Stars           from '@components/Stars';
import mixins from './mixins';
const emptyItem = { id: -1, name: 'No-Name Hotel' };


export default {
    mixins: [mixins],
    props: {
        value: { required: true },
        placeholderInfo: { type: Object, required: true },
        isTriggerSearch: { type: Boolean, default: false },
        multiSelectStyle: {},
        showIcon: {type: Boolean, default: true},
        params: {
            type: Object,
            default: null
        }
    },


    computed: {
        hotelSelected: {
            get: function () {
                return this.value ? JSON.parse(JSON.stringify(this.value)) : emptyItem;
            },
            set: function (value) {
                this.$emit('input', value.id !== -1 ? value : null);
            }
        }
    },


    data: function () {
        return {
            items: [emptyItem],

            queryString: '',
            isLoadingItems: false
        }
    },

    mounted: function () {
        this.searchHotels('', true)
    },


    methods: {
        searchHotels: function (query, isTriggerAction = false) {
            if (this.placeholderInfo.area || this.placeholderInfo.place) {
                if (query || isTriggerAction) {
                    this.queryString = query;

                    this.isLoadingItems = true;
                    this.callCancelRequest();

                    Promise.all([
                        ...this.placeholderInfo.area ? [axios.get('/api/hotels?name=' + query + '&place.area.id=' + this.placeholderInfo.area.id, {params: this.params, cancelToken: this.cancelSource.token})] : [],
                        ...this.placeholderInfo.place ? [axios.get('/api/hotels?name=' + query + '&place.id=' + this.placeholderInfo.place.id, {params: this.params, cancelToken: this.cancelSource.token})] : []
                    ]).then(([placeHotels, areaHotels]) => {
                        const list = uniqBy([
                            ...placeHotels ? placeHotels.data : [],
                            ...areaHotels ? areaHotels.data : []
                        ], hotel => hotel.id)
                        this.items = [emptyItem, ...list];
                    }, error => {
                        if(!axios.isCancel(error)) {
                            notifyError('Die Hotels konnten nicht geladen werden! Server error!');
                        }
                    })
                        .then(() => {
                            this.isLoadingItems = false;
                        });
                }
            }
            else {

                this.queryString = query;

                this.isLoadingItems = true;

                this.callCancelRequest();
                axios.get('/api/hotels?name='+query, {params: this.params, cancelToken: this.cancelSource.token}).then(response => {
                    this.items = [emptyItem, ...response.data];
                }, error => {
                    if(!axios.isCancel(error)) {
                        notifyError('Die Hotels konnten nicht geladen werden! Server error!');
                    }
                })
                    .then(() => {
                        this.isLoadingItems = false;
                    });

            }
            },
        fillLocation: function (input) {
            this.hotelSelected = input;
            if(!this.placeholderInfo.area && !this.placeholderInfo.place){
                this.placeholderInfo.area= input.place.area
                this.$emit('fillLocation',input);
            }
        },
    },
    watch: {
        placeholderInfo: {
            handler: function () {
                this.searchHotels(this.queryString, true);
                //this.hotelSelected = emptyItem;
            },
            deep: true
        },


        isTriggerSearch: {
            handler: function (isTrue) {
                if (isTrue) {
                    this.searchHotels(this.queryString, true);
                }
            },
            immediate: true
        },
        params () {
            this.searchHotels(this.queryString, true);
        }
    },


    components: {
        Multiselect,
        Rating,
        Stars
    }
}
</script>
