<template>
    <multiselect
        ref="select"
        @open="checkParamsChange"
        v-model="innerValue"
        track-by="id"
        v-bind:custom-label="customLabel"
        v-bind:options="options"
        v-bind:loading="isLoading"
        :limit="limit"

        :main-element="mainElement"
        @mainElement="input => $emit('mainElement', input)"
        :has-main-element-select="hasMainElementSelect"
        v-on:search-change="onSearch"
        v-bind:multiple="multiple"

        v-bind:allow-empty="allowEmpty"
        v-bind:internal-search="false"

        v-bind:placeholder="_placeholder"
        v-bind:disabled="disabled"
        v-on:select="onSelect"
        v-on:close="$emit('close', tempString)"
    >
        <div
            slot="clear"
            class="multiselect__clear"
            style="z-index: 1;"
            v-if="!!innerValue && clearable && !multiple"
            v-on:click.prevent.stop="innerValue = (multiple ? [] : null)"
        ></div>

        <span slot="caret" v-if="creatable">
            <a
                v-on:click.prevent.stop="$emit('create')"
                v-bind:href="`/${_api}?add`"
                class="multiselect-crud-link"
            ><i class="material-icons">add</i></a>
        </span>
        <span slot="caret" v-else-if="options.length === 0">
            <a
                v-if         = "crudLinkTitle !== '' && isEmptyResults"
                class        = "multiselect-crud-link"
                v-bind:href  = "'/' + _api + '?add'"
                v-bind:title = "crudLinkTitle"
                target       = "_blank"
            ><i class="material-icons">add</i></a>
        </span>
        <template slot="selection" slot-scope="{ values, search, isOpen, removeElement }">
            <slot name="selection" v-bind="{values, search, isOpen, removeElement}" />
        </template>
    </multiselect>
    <!-- v-on:open="onOpen"-->
</template>



<script>
import get          from 'lodash/get';
import debounce     from 'lodash/debounce';
import axios        from 'axios';
import Multiselect  from '@components/form/Multiselect';
import mixins from "@orders/placeholders/components/forms/mixins";
import {notifyError} from "@components/Notification";

export default {
    mixins: [mixins],

    props: {
        value: {},
        limit: {
            type: Number,
            default: 99999
        },
        customLabel: { default: () => item => item.name },

        allowEmpty:    { type: Boolean, default: true },
        multiple:      { type: Boolean, default: false },
        clearable:     { type: Boolean, default: true },
        disabled:      { type: Boolean, default: false },

        placeholder:   { type: String,  default: '' },
        api:           { type: String,  default: '' },
        searchParam:   { type: String,  default: '_search' },

        params:        { type: Object, },

        crudLinkTitle: { type: String, default: '' },

        initSearch: {default: false},
        disableWatcher: {},

        hasMainElementSelect: {
            type: Boolean,
            default: false,
        },
        mainElement: {
            type: Object,
            default: null,
        },
    },


    // this.$options from components extending this
    placeholder: '',
    api: '',


    data: function () {
        return {
            currentParams: "",
            string: '',
            tempString: '',
            options: [],
            isLoading: false,

            isEmptyResults: false
        }
    },


    computed: {
        creatable: function() {
            return !!this.$listeners.create
        },

        innerValue: {
            get: function () { return this.value },
            set: function (value) { this.$emit('input', value) }
        },


        _placeholder: function () {
            return this.placeholder !== "" ? this.placeholder : this.$options.placeholder;
        },


        _params: function () {
            return {
                [this.searchParam]: this.string,
                ...this.params
            }
        },


        _api: function () {
            return this.$options.api !== '' ? this.$options.api : this.api;
        },
    },


    methods: {
        search: function () {
            this.isLoading = true;
            this.callCancelRequest();

            axios.get(`/api/${this._api}`, {
                params: this._params,
                cancelToken: this.cancelSource.token
            }).then(response => {
                this.$emit('lastSearch', this.string)

                this.isLoading = false;
                this.options = response.data;

                this.isEmptyResults = this.options.length === 0;

                this.$emit('load', response.data);
            }, error => {
            })
        },
        checkParamsChange: function() {
            if (this.params !== this.currentParams) {
                this.search();
                this.currentParams = this.params;
            }
        },

        onSearch: function (string = '') {
            this.tempString= string;
            this.handleSearch(string);
        },


        handleSearch: debounce(function (string = '') {
            if (string) {
                this.string = string;

                this.search();
            }
        },  900),


        /*onOpen: function () {
            if (!this.options.length) {
                this.search();
            }
        }*/

        onSelect: function (value) {
            this.closeFix = true;
            this.$emit('select', value)
        },

        focus: function () {
            this.$refs.select.$el.focus()
        }
    },

    mounted: function() {
        if(this.initSearch) {
            this.string = typeof this.initSearch !== 'boolean' ? this.initSearch : ''
            this.search();
        }
    },


    watch: {
        params: {
            deep: true,
            handler: function () {
                    if (!this.disableWatcher) {
                        this.search()
                    }
                }
        },


        api: function () {
            this.search()
        }
    },


    components: { Multiselect }

}
</script>
